import useIsDesktop from "desktop/useIsDesktop";
import { navigate } from "gatsby";
import { useEffect } from "react";

import Menu from "../../components/Profile/Menu";
import { SEO } from "../../components/SEO";
import withProtection from "../../highOrderComponents/withProtection";

function Profile({ pageContext }): JSX.Element {
	const isD = useIsDesktop();

	useEffect(() => {
		if (isD) void navigate("moje-udaje/");
	}, [isD]);

	if (isD) return <></>;

	return <Menu />;
}

export default withProtection(Profile);

export const Head = (): JSX.Element => (
	<SEO title="Profil">
		<meta name="robots" content="noindex" />
	</SEO>
);
